<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-tabs class="md-primary" md-sync-route>
        <md-tab
          id="tab-character-prices-list"
          md-label="Прокачка героев"
          to="/level-up-prices/characters"
          exact
        />
        <md-tab
          id="tab-skill-prices-list"
          md-label="Прокачка скиллов"
          to="/level-up-prices/skill"
        />
      </md-tabs>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
